




















































































































































import { Component, Mixins, Watch } from 'vue-property-decorator';
import { View } from 'client-website-ts-library/plugins';
import { EmbeddedContent, EmbeddedContentType } from 'client-website-ts-library/types/EmbeddedContent/EmbeddedContent';

import VideoBannerExternal from '@/components/VideoBannerExternal.vue';
import ListingCardCycler from '@/components/Cycler/ListingCardCycler.vue';

import VideoBanner from '../components/VideoBanner.vue';

@Component({
  components: {
    VideoBanner,
    VideoBannerExternal,
    ListingCardCycler,
  },
})
export default class WhyListWithUs extends Mixins(View) {
  private eb: EmbeddedContent = {
    Type: EmbeddedContentType.Youtube,
    EmbedId: 'uaJ-0XAMmkQ',
  };
}
